import { template as template_990d7743f7464977ac08347f6c6e8e79 } from "@ember/template-compiler";
const FKControlMenuContainer = template_990d7743f7464977ac08347f6c6e8e79(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
