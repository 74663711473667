import { template as template_c432a4bac9b3464e9a65838188d2c5bc } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_c432a4bac9b3464e9a65838188d2c5bc(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
